export const navItems = [
    {
        id: 1,
        title: 'Home',
        path: './',
    },
    {
        id: 2,
        title: 'Resume',
        path: './resume',
    },
    {
        id: 4,
        title: 'Portfolio',
        path: './portfolio',
    },
    {
        id: 5,
        title: 'About',
        path: './about',
    },
    {
        id: 6,
        title: 'Contact',
        path: './contact',
    },
    // {
    //     id: 7,
    //     title: 'Writing Wall',
    //     path: './wall',
    // }
];